import moment from "moment";
import {
  fetchSurvey,
  downloadSurvey,
  fetchParentSurveys,
  fetchCompetencyLevels
} from "@/services/api/surveys";
import { canViewResults } from "@/services/utils/assessments";
import {
  fetchAssessmentResults,
  fetchAssessmentsHistoryResults,
} from "@/services/api/results";
import { getCompletedAssessments } from "@/services/api/dashboard";
import forEach from "lodash/forEach";

const getDefaultState = () => ({
  assessment: null,
  assessments: [],
  competencyResults: null,
  subcompetencyResults: null,
  subcompetencyLegend: null,
  historyResults: null,
  parentAssessments: [],
});

const actions = {
  resetAssessmentsState({ commit }) {
    commit("resetState");
  },
  async getAssessment({ commit }, { id }) {
    const rspAssessment = await fetchSurvey(id);
    const assessment = rspAssessment.data.data;
    const mappedAssessment = {
      currentQuestion: assessment.answeredQuestionsCount + 1,
      inProgress: assessment.answeredQuestionsCount !== 0,
      title: assessment.name,
      launchedOn: moment(assessment.openingTime).format("ddd, MMM D, YYYY"),
      dueOn: moment(assessment.closingTime).format("ddd, MMM D, YYYY"),
      completedOn: moment(assessment.completionDate).format(
        "ddd, MMM D, YYYY"
      ),
      id: assessment.id,
      totalQuestions: assessment.questionsCount,
      canDownload: assessment.userResultPermission === 3,
      surveyType: assessment.surveyType,
    };
    const rspCompetencyLevels = await fetchCompetencyLevels(id);
    mappedAssessment.competencyLevels = rspCompetencyLevels.data.data.map((item) => {
      return {
        score: item.score,
        name: item.name
      }
    });
    commit("SET_ASSESSMENT", mappedAssessment);
  },
  getSurveys({ commit }) {
    getCompletedAssessments().then((rsp) => {
      const assessments = rsp.data.data
        .filter((item) =>
          canViewResults(
            item.resultsPermission,
            item.surveyPermission,
            item.closingTime
          )
        )
        .map((assessment) => ({
          label: assessment.name,
          id: assessment.id,
          completedAt: assessment.completedAt,
        }));
      commit("SET_ASSESSMENTS", assessments);
    });
  },
  getParentSurveys({ commit }) {
    return fetchParentSurveys().then((rsp) => {
      const assessments = rsp.data.data.map((assessment) => ({
        label: assessment.name,
        id: assessment.id,
      }));
      commit("SET_PARENT_ASSESSMENTS", assessments);
    });
  },
  downloadPdf(_, { id, competencyImg, subcompetencyImg }) {
    downloadSurvey(id, competencyImg, subcompetencyImg).then((rsp) => {
      const file = new Blob([rsp.data], { type: "application/pdf" });
      const filename = "Results.pdf";
      const data = URL.createObjectURL(file);
      const a = document.createElement("a");
      a.href = data;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    });
  },
  getAssessmentCompetencyResults({ commit }, { id }) {
    fetchAssessmentResults(id, "competency").then((rsp) => {
      const results = rsp.data.data;
      const subCompLegend = [];
      const categories = [];
      const targetData = [];
      const scoreData = [];
      const tableData = [];
      forEach(results, (result) => {
        subCompLegend.push({
          label: result.name,
          color: result.color,
        });
        categories.push({
          label: result.name,
        });
        targetData.push({
          value: result.target,
        });
        scoreData.push({
          value: result.score,
          color: result.color,
        });
        tableData.push({
          competency: result.name,
          actual: result.score,
          target: result.target,
        });
      });
      const lineData = {
        seriesName: "Target",
        renderAs: "line",
        color: "#F04E23",
        data: targetData,
      };
      const barData = {
        renderAs: "bar",
        seriesName: "Assessment Results",
        data: scoreData,
      };
      commit("SET_COMPETENCY_RESULTS", {
        categories,
        lineData,
        barData,
        tableData,
      });
      commit("SET_SUBCOMPETENCY_LEGEND", subCompLegend);
    });
  },
  getAssessmentSubcompetencyResults({ commit }, { id }) {
    fetchAssessmentResults(id, "subcompetency").then((rsp) => {
      const results = rsp.data.data;
      const categories = [];
      const targetData = [];
      const scoreData = [];
      const tableData = [];
      forEach(results, (result) => {
        categories.push({
          label: result.name,
          category: result.parentCompetencyName,
        });
        targetData.push({
          value: result.target,
        });
        scoreData.push({
          value: result.score,
          color: result.color,
        });
        tableData.push({
          subcompetency: result.name,
          competency: result.parentCompetencyName,
          actual: result.score,
          target: result.target,
        });
      });
      const lineData = {
        seriesName: "Target",
        renderAs: "line",
        color: "#F04E23",
        data: targetData,
      };
      const barData = {
        renderAs: "bar",
        seriesName: "Assessment Results",
        data: scoreData,
      };
      commit("SET_SUBCOMPETENCY_RESULTS", {
        categories,
        lineData,
        barData,
        tableData,
      });
    });
  },
  getHistoryResults({ commit }, { id }) {
    fetchAssessmentsHistoryResults(id).then((rsp) => {
      commit("SET_HISTORY_RESULTS", rsp.data.data);
    });
  },
};

const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ASSESSMENT(state, assessment) {
    state.assessment = assessment;
  },
  SET_ASSESSMENTS(state, assessments) {
    state.assessments = assessments;
  },
  SET_PARENT_ASSESSMENTS(state, assessments) {
    state.parentAssessments = assessments;
  },
  SET_COMPETENCY_RESULTS(state, results) {
    state.competencyResults = results;
  },
  SET_SUBCOMPETENCY_RESULTS(state, results) {
    state.subcompetencyResults = results;
  },
  SET_SUBCOMPETENCY_LEGEND(state, legend) {
    state.subcompetencyLegend = legend;
  },
  SET_HISTORY_RESULTS(state, results) {
    state.historyResults = results;
  },
};

const setOpacity = (color, amount) =>
  "#" +
  color
    .replace(/^#/, "")
    .replace(/../g, (color) =>
      (
        "0" +
        Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
      ).substr(-2)
    );

const getters = {
  getAssessment: (state) => state.assessment,
  getCompetency: (state) => (id) =>
    state.historyResults &&
    state.historyResults.find((competency) => competency.id === id),
  getSubcompetency: (_, getters) => (id) =>
    getters.getCompetency(id).subCompetency,
  getSubcompetencies: (_, getters) => (id) =>
    getters.getSubcompetency(id).map((subCompetency) => ({
      label: subCompetency.name,
      color: subCompetency.color,
      id: subCompetency.id,
    })),
  getCompetencies: (state) =>
    state.historyResults &&
    state.historyResults.map((competency) => ({
      label: competency.name,
      color: competency.color,
      id: competency.id,
    })),
  getCompetencyResult: (_, getters) => (id) => {
    const competency = getters.getCompetency(id);
    const bars = [];
    const lines = [];
    const categories = [];
    forEach(competency.results, (result) => {
      bars.push({
        value: result.score,
      });
      lines.push({
        value: result.target,
      });
      categories.push({
        label: moment(result.date).format("MM/YYYY"),
      });
    });
    return {
      barData: {
        data: bars,
        renderAs: "bar",
      },
      lineData: {
        data: lines,
        color: "#F04E23",
        renderAs: "line",
        seriesName: "Target per year",
      },
      categories,
    };
  },
  getSubcompetenciesResult: (_, getters) => (id) => {
    const competency = getters.getCompetency(id);
    const baseColor = competency.color;
    const categories = [];
    const data = {};
    forEach(competency.subCompetency, (subCompetency) => {
      categories.push({
        label: subCompetency.name,
      });
      forEach(subCompetency.results, (result) => {
        if (data[result.date]) {
          if (data[result.date].targets) {
            data[result.date].targets.push({ value: result.target });
          } else {
            data[result.date].targets = [];
            data[result.date].targets.push({ value: result.target });
          }
          if (data[result.date].scores) {
            data[result.date].scores.push({ value: result.score });
          } else {
            data[result.date].scores = [];
            data[result.date].scores.push({ value: result.score });
          }
        } else {
          data[result.date] = {};
          data[result.date].date = moment(result.date).format("MM/YYYY");
          if (data[result.date].targets) {
            data[result.date].targets.push({ value: result.target });
          } else {
            data[result.date].targets = [];
            data[result.date].targets.push({ value: result.target });
          }
          if (data[result.date].scores) {
            data[result.date].scores.push({ value: result.score });
          } else {
            data[result.date].scores = [];
            data[result.date].scores.push({ value: result.score });
          }
        }
      });
    });
    const realData = [];
    Object.keys(data).map((key, index) => {
      const scores = {
        color: setOpacity(
          baseColor,
          (100 / Object.keys(data).length) * (index + 1)
        ),
        data: data[key].scores,
        seriesName: `${data[key].date} Actual`,
      };
      realData.push(scores);
      const target = {
        color: setOpacity(baseColor, -100),
        data: data[key].targets,
        seriesName: `${data[key].date} Target`,
      };
      realData.push(target);
    });
    return {
      categories,
      data: realData,
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
