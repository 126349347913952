import * as subscriptionsApi from '../../services/api/subscriptions';
import router from '@/router';
import moment from "moment";

const getDefaultState = () => ({
  subscriptionDetails: undefined,
  form: {
    startDate: moment(new Date(Date.now())).format("YYYY-MM-DDTHH:mm:ss"),
    endDate: moment(new Date(new Date(new Date().setDate(new Date().getDate() + 364)).setHours(23, 59, 0, 0))).format("YYYY-MM-DDTHH:mm:ss"),
    timezone: { label: "(UTC) Coordinated Universal Time", id: "UTC" },
    lead: false,
    pastResults: false,
    reminders: [],
    selectedProducts: [],
    exportPermission: false
  },
});

const actions = {
  resetSubscriptionsState({ commit }) {
    commit('resetState');
  },
  async getSubscriptionDetails({ commit, dispatch }, organizationId) {
    const details = await subscriptionsApi.getSubscriptionDetails(organizationId);
    const { data } = details.data;
    commit('SET_SUBSCRIPTION_DETAILS', data);
    commit('SET_SUBSCRIPTION_PRODUCTS', data.products);
    if (data.subscriptionStatus !== 0) {
      dispatch('setFormValue', { key: 'startDate', value: data.startDate });
      dispatch('setFormValue', { key: 'endDate', value: data.endDate });
      dispatch('setFormValue', { key: 'lead', value: data.isUseForLeadGeneration });
      dispatch('setFormValue', { key: 'pastResults', value: data.isAccessForPastResults });
      dispatch('setFormValue', { key: 'reminders', value: data.reminderNrDaysBeforeEndDate });
      dispatch('setFormValue', { key: 'exportPermission', value: data.allowAdminExportPermission });
    }
    if (data.timezone) dispatch('setFormValue', { key: 'timezone', value: data.timezone });
  },
  setFormValue({ commit }, { key, value }) {
    commit('SET_FORM_VALUE', { key, value });
  },
  async updateSubscription({ state, dispatch }, { organizationId, action }) {
    // Action enum:
    // Activate = 1,
    // Deactivate = 2,
    // Suspend = 3,
    // Update = 4,
    const payload = {
      organizationId,
      startDate: state.form.startDate,
      endDate: state.form.endDate,
      timezone: state.form.timezone,
      reminderNrDaysBeforeEndDate: state.form.reminders.map((el) => parseInt(el, 10)),
      isUseForLeadGeneration: state.form.lead,
      isAccessForPastResults: state.form.pastResults,
      updateOrganizationProductsRequest: state.form.selectedProducts.map((product) => ({
        productId: product.id,
        isSelected: product.isSelected,
      })),
      allowAdminExportPermission: state.form.exportPermission,
      action,
    };
    await subscriptionsApi.updateSubscription(payload);
    router.push(`/company/${organizationId}/invites`);
    if (action === 1) {
      dispatch(
        'alerts/showSuccess',
        {
          messageHeader: 'New Subscription Activated',
          message: 'Set the number of desired invites or check the exiting ones.',
        },
        { root: true }
      );
    }
  },
  async updateSubscriptionStatus({ state, dispatch }, { organizationId, action }) {
    // Action enum:
    // Activate = 1,
    // Deactivate = 2,
    // Suspend = 3,
    // Update = 4,
    const payload = {
      organizationId,
      startDate: state.form.startDate,
      endDate: state.form.endDate,
      timezone: state.form.timezone,
      reminderNrDaysBeforeEndDate: state.form.reminders.map((el) => parseInt(el, 10)),
      isUseForLeadGeneration: state.form.lead,
      isAccessForPastResults: state.form.pastResults,
      action,
      allowAdminExportPermission: state.form.exportPermission,
    };
    await subscriptionsApi.updateSubscriptionStatus(payload);
    router.push(`/company/${organizationId}/invites`);
    if (action === 2) {
      dispatch(
        'alerts/showSuccess',
        {
          messageHeader: 'Subscription Deactivated',
          message: 'This customer can longer access the platform’s functionality.',
        },
        { root: true }
      );
    }
  },
};
const state = getDefaultState();

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  SET_SUBSCRIPTION_DETAILS(state, details) {
    state.subscriptionDetails = details;
  },
  SET_FORM_VALUE(state, { key, value }) {
    state.form[key] = value;
  },
  SET_SUBSCRIPTION_PRODUCTS(state, prods) {
    state.form.selectedProducts = prods.map((prod) => ({
      name: prod.name,
      isSelected: prod.alreadyBelongsToOrganization,
      id: prod.productId,
      canBeEdited: !prod.alreadyBelongsToOrganization,
      canBeDisabled: prod.canBeDisabled,
      backgroundColor: prod.backgroundColor,
    }));
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
