import { apiCall } from "./api";

const baseUrl = 'api/v1/Organization';

export const getSubscriptionDetails = (organizationId) =>
  apiCall("GET", `${baseUrl}/get-organization-subscription-details?organizationId=${organizationId}`);

export const updateSubscription = (payload) =>
  apiCall("POST", `${baseUrl}/update-subscription-details`, payload);

export const updateSubscriptionStatus = (payload) =>
  apiCall("POST", `${baseUrl}/update-organization-subscription-status`, payload);

export const getSubscriptionHistory = (pageNumber, pageSize = 20, organizationId) =>
  apiCall("POST", `${baseUrl}/get-organization-subscription-history`, {
      pageNumber,
      pageSize,
      organizationId
  });