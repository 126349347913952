<template>
  <div class="bg-white rounded shadow p-2 pt-3">
    <SubscriptionStatusBox :subStatus="details.subscriptionStatus" :onAdmin="onAdmin" />
    <div class="w-full flex items-center flex-row px-2 mb-2" v-if="hasNoStatus">
      <div class="flex">
        <p class="text-grey-dark-1">
          No subscription active.
        </p>
      </div>
      <div class="flex ml-auto" @click="activateSubscription">
        <router-link to="subscription-details" v-if="showActivateSubscription">
          <Button text="Activate Subscription" size="medium" />
        </router-link>
      </div>
    </div>
    <div v-else class="flex flex-col">
      <div class="flex flex-row mx-2 space-x-10 mt-2">
        <div class="flex flex-col">
          <p class="text-grey-light text-sm-2">
            Time Zone
          </p>
          <p class="text-grey-dark-1 text-md-2">
            (UTC) Coordinated Universal Time
          </p>
        </div>
        <!-- Content item -->
        <div class="flex flex-col">
          <p class="text-grey-light text-sm-2">
            Start Date
          </p>
          <p class="text-grey-dark-1 text-md-2">
            {{ formatDate(details.startDate) }}
          </p>
        </div>
        <!-- End Content item -->
        <!-- Content item -->
        <div class="flex flex-col">
          <p class="text-grey-light text-sm-2">
            End Date
          </p>
          <p class="text-grey-dark-1 text-md-2">
            {{ formatDate(details.endDate) }}
          </p>
        </div>
        <!-- End Content item -->
        <!-- Content item -->
        <div class="flex flex-col">
          <p class="text-grey-light text-sm-2">
            Products
          </p>
          <p class="text-grey-dark-1 text-md-2">
            {{ selectedProducts }}
          </p>
        </div>
        <!-- End Content item -->
      </div>
      <div class="flex flex-row space-x-2 mt-3" v-if="!onAdmin">
        <div class="ml-auto">
          <router-link to="subscription-history">
            <Button
              text="View History"
              size="medium"
              type="secondary"
              :iconRight="arrow"
            />
          </router-link>
        </div>
        <div>
          <router-link to="subscription-details">
            <Button
              text="Manage"
              size="medium"
              type="secondary"
              :iconRight="arrow"
            />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import Button from "@/components/Button/Button.vue";
import arrow from "@/assets/img/icons/arrow-right.svg";
import SubscriptionStatusBox from "../../../../components/SubscriptionStatusBox/SubscriptionStatusBox.vue";
import moment from "moment";
export default {
  name: "SubscriptionInfoCard",
  data: () => ({
    arrow,
  }),
  components: {
    Button,
    SubscriptionStatusBox,
  },
  props: {
    details: {
      type: Object,
      default: () => ({}),
    },
    onAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      subDetails: (state) => state.subscriptions.subscriptionDetails,
      subForm: (state) => state.subscriptions.form,
    }),
    ...mapGetters({
      isImpersonating: "auth/isImpersonating",
      getRole: "home/getRole",
    }),
    showActivateSubscription() {
      return this.getRole === 'seller' && !this.isImpersonating;
    },
    selectedProducts() {
      const numOfProducts = this.subDetails?.products.length;
      const selectedProd = this.subDetails?.products.filter(
        (item) => item.alreadyBelongsToOrganization === true
      ).length;
      return selectedProd === numOfProducts
        ? "All"
        : `${selectedProd}/${numOfProducts}`;
    },
    hasNoStatus() {
      return this.details.subscriptionStatus === 0;
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("ddd, D MMM YYYY");
    },
    activateSubscription() {
      this.subForm.startDate = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
      this.subForm.endDate = moment(
        new Date(
          new Date(new Date().setDate(new Date().getDate() + 364)).setHours(
            23,
            59,
            0,
            0
          )
        )
      ).format("YYYY-MM-DDTHH:mm:ss");
    },
  },
};
</script>
